import React from "react";
import { Routes, Route } from "react-router-dom";
import { Stack } from "@mui/system";
import Layout from "../../components/organisms/Layout";
import SideNavBar from "./SideNavBar";
import { baseRoute, typographyRoutes, formControlsRoutes, navigationRoutes, muiLabsRoutes, layoutRoutes, dataDisplayRoutes } from "./routes";
import { Box, Typography } from "@mui/material";
import MainContent from "./MainContent";

const MuiComponents = () => {
  const getRoutes = () => {
    return [
      ...baseRoute,
      ...typographyRoutes,
      ...formControlsRoutes, 
      ...layoutRoutes,
      ...navigationRoutes,
      ...dataDisplayRoutes,
      ...muiLabsRoutes
    ].map(({ path, component, index }) =>
    index ? (
      <Route index={true} element={component} key={path}></Route>
    ) : (
      <Route index={false} path={path} element={component} key={path}></Route>
    )
  );
};
  return (
    <Stack>
      <Layout SideNavBar={SideNavBar}>
        <Box>
          <Typography variant="h3" component="h2" align="center" sx={{ mb: 2}} color="secondary">Material UI Components </Typography>
          <Routes>
            {getRoutes()}
          </Routes>
        </Box>
      </Layout>
    </Stack>
  )
}

export default MuiComponents;
import Layout from "../../components/organisms/Layout";
import MainContent from "./MainContent";
import SideNavBar from "./SideNavBar";
import { NotesAppRoutes } from "./routes";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/system";
import { Stack } from "@mui/material";

const NotesApp = () => {
  const getRoutes = () => {
    return [...NotesAppRoutes].map(({ path, component, index }) =>
      index ? (
        <Route index={true} element={component} key={path}></Route>
      ) : (
        <Route index={false} path={path} element={component} key={path}></Route>
      )
    );
  };
  return (
    <Stack>
      <Layout SideNavBar={SideNavBar}>
        <Box>
          <MainContent />
          <Routes>{getRoutes()}</Routes>
        </Box>
      </Layout>
    </Stack>
  );
};

export default NotesApp;

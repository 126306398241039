import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme, colors } from "@mui/material";
// import { purple } from "@mui/material/colors";
// import NoteApp from "./NoteApp/index";
import HomePageApp from "./apps/HomePageApp";
import MuiComponents from "./apps/MuiComponents/index";
import FoodApp from "./apps/FoodApp";
import ExerciseApp from "./apps/ExerciseApp";
import BlogsApp from "./apps/BlogsApp";
import NotesApp from "./apps/NotesApp";
import WebDesignApp from "./apps/WebDesignApp";
import YoutubeApp from "./apps/YoutubeApp";
import SocialMediaApp from "./apps/SocialMediaApp";
import LaunchPage from "./components/pages/LaunchPage";
import Signup from "./components/pages/Signup";
import Login from "./components/pages/Login";
import { useAuthContext } from './hooks/useAuthContext'

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#fefefe",
//     },
//     secondary: purple,
//   },
//   typography: {
//     fontFamily: "Quicksand",
//     fontWeightLight: 400,
//     fontWeightRegular: 500,
//     fontWeightMedium: 600,
//     fontWeightBold: 700,
//   },
// });

const theme = createTheme({
  status: {
    danger: '#e53e3e'
  },
  palette: {
    secondary: {
      main: colors.orange[500]
    },
    neutral: {
      main: colors.grey[500],
      darker: colors.grey[700]
    }
  }
})

function App() {
  const { state } = useAuthContext();
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          {/* <Route path="/" element={<LaunchPage />}></Route> */}
          <Route path="/" element={<LaunchPage />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/forgotpassword" element={state.isAuthenticated ? <Signup /> : <Navigate to="/login" />}></Route>
          <Route path="/signup" element={state.isAuthenticated ? <Signup /> : <Navigate to="/login" />}></Route>
          <Route path="/home" element={state.isAuthenticated ? <HomePageApp /> : <Navigate to="/login" />}></Route>
          <Route path="/components/*" element={state.isAuthenticated ?  <MuiComponents /> : <Navigate to="/login" />}></Route>
          <Route path="/notes/*" element={state.isAuthenticated ?  <NotesApp /> : <Navigate to="/login" />}></Route>
          <Route path="/food" element={state.isAuthenticated ? <FoodApp /> : <Navigate to="/login" />}></Route>
          <Route path="/exercise" element={state.isAuthenticated ? <ExerciseApp /> : <Navigate to="/login" />}></Route>
          <Route path="/blogs" element={state.isAuthenticated ? <BlogsApp /> : <Navigate to="/login" />}></Route>
          <Route path="/webdesign" element={state.isAuthenticated ? <WebDesignApp /> : <Navigate to="/login" />}></Route>
          <Route path="/youtube" element={state.isAuthenticated ? <YoutubeApp /> : <Navigate to="/login" />}></Route>
          <Route path="/socialmedia" element={state.isAuthenticated ? <SocialMediaApp /> : <Navigate to="/login" />}></Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;

import React from "react";
import { Avatar, Card, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import { blue, green, pink, yellow } from "@mui/material/colors";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { makeStyles } from '@mui/styles';
import { NotesProps } from "./Notes";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: (note: NotesProps) => {
      if (note.category === 'work') {
        return yellow[700]
      }
      if (note.category === 'money') {
        return green[500]
      }
      if (note.category === 'todos') {
        return pink[500]
      }
      return blue[500]
    },
  }
})

type NoteCardProps = {
  note: NotesProps,
  handleDelete: (id: number) => void
}

const NoteCard = ({ note, handleDelete }: NoteCardProps) => {
  const classes = useStyles(note)
  return (
    <div>
      <Card elevation={1}>
        <CardHeader
          avatar={
            <Avatar className={classes.avatar}>
              {note.category[0].toUpperCase()}
            </Avatar>}
          action={
            <IconButton onClick={() => handleDelete(note.id)}>
              <DeleteOutlineIcon />
            </IconButton>
          }
          title={note.title}
          subheader={note.category}
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            { note.details }
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default NoteCard;
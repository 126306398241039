import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Paper,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import Copyright from "../../molecules/Copyright";
import Logo from "../../molecules/Logo";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { useSignup } from "../../../hooks/useSignup";

export interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  acceptTnC: boolean;
}

const initialValue: FormData = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  acceptTnC: false,
};

const Signup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>(initialValue);
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const { signup, error, isLoading } = useSignup();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validate()) {
      // Submit form data to server
      await signup(formData);
      if (error === null) {
        setFormData(initialValue);
        navigate("/home");
        console.log("Signup successful")
      }
    }
  };

  const validate = () => {
    let tempErrors: Partial<FormData> = {};
    if (!formData.firstName) {
      tempErrors.firstName = "First Name is required";
    }
    if (!formData.lastName) {
      tempErrors.lastName = "Last Name is required";
    }
    if (!formData.email) {
      tempErrors.email = "Email is required";
    }
    if (!formData.password) {
      tempErrors.password = "Password is required";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = event.target;
    switch (type) {
      case 'checkbox':
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: checked,
        }));
        break;
      default:
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
        break;
    }
  };

  return (
    <Box sx={{ minHeight: "100vh", bgcolor: "black", display: "flex" }}>
      <Container
        maxWidth="sm"
        component="main"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Paper elevation={3} sx={{ p: 6 }}>
          <Stack spacing={4}>
            <Logo />
            {error && <div className="error">{error.message}</div>}
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={2}
                  sx={{ "& .MuiTextField-root": { flexGrow: 1 } }}
                >
                  <TextField
                    required
                    name="firstName"
                    label="First Name"
                    variant="outlined"
                    value={formData.firstName}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                    onChange={handleChange}
                  />
                  <TextField
                    required
                    name="lastName"
                    label="Last Name"
                    variant="outlined"
                    value={formData.lastName}
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                    onChange={handleChange}
                  />
                </Stack>
                <TextField
                  required
                  name="email"
                  label="Email"
                  variant="outlined"
                  value={formData.email}
                  error={!!errors.email}
                  helperText={errors.email}
                  onChange={handleChange}
                />
                <TextField
                  required
                  name="password"
                  label="Password"
                  variant="outlined"
                  value={formData.password}
                  error={!!errors.password}
                  helperText={errors.password}
                  onChange={handleChange}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="acceptTnC"
                      checked={!!formData.acceptTnC}
                      onChange={handleChange}
                      size="medium"
                      color="primary"
                    />
                  }
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
                <Button variant="contained" type="submit" disabled={isLoading}>
                  SIGN UP
                </Button>
              </Stack>
            </form>
            <Stack display="flex" alignItems="end">
              <Link component={RouterLink} to="/login">
                Already have an account? Sign in
              </Link>
            </Stack>
            <Copyright />
          </Stack>
        </Paper>
      </Container>
    </Box>
  );
};

export default Signup;

import { IconButton, Typography } from "@mui/material";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import { useNavigate } from "react-router-dom";

type LogoProps = {
  color?: String
}

const Logo= ({ color } : LogoProps) => {
  const navigate = useNavigate();
  return (
    <IconButton
      size="large"
      edge="start"
      aria-label="logo"
      color="primary"
      sx={{ flexGrow: 1, p: 0, m: 0, "color": `${color}` }}
      onClick={() => navigate("/")}
    >
      <Diversity2Icon />
      <Typography
        variant="h6"
        sx={{
          pl: 1,
          fontFamily: "monospace",
          fontWeight: 700,
          letterSpacing: ".05rem",
        }}
      >
        DIY Makhija
      </Typography>
    </IconButton>
  );
};

export default Logo;

import { Box } from "@mui/system";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  RouteObjectProps,
  NotesAppRoutes,
} from "./routes";
import { useNavigate } from "react-router-dom";

export type SideNavBarProps = {
  open: boolean;
};

const SideNavBar: React.FC<SideNavBarProps> = ({ open }) => {
  const navigate = useNavigate();
  const createRoutes = (routes: RouteObjectProps[]) => {
    return (
      <List>
        {routes.map((route) => (
          <ListItem
            key={route.label}
            disablePadding
            sx={{ display: "block" }}
            onClick={() => navigate(route.routeName)}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {route.icon}
              </ListItemIcon>
              <ListItemText
                primary={route.label}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  };
  return (
    <Box>
      {createRoutes(NotesAppRoutes)}
    </Box>
  );
};

export default SideNavBar;

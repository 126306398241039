import React, { createContext, useReducer } from 'react';

export interface userType {
  email: string;
  token: string;
}

// Define the shape of our state
export interface AuthState {
  isAuthenticated: boolean;
  user: userType | null;
}

// Define the available actions
export type AuthAction =
  | { type: 'LOGIN'; payload: Object }
  | { type: 'LOGOUT' };

// Define the initial state
const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
};

// Define our reducer function
const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'LOGIN':
      return {
        isAuthenticated: true,
        user: action.payload as userType,
      };
    case 'LOGOUT':
      return {
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

// Create the AuthContext
export const AuthContext = createContext<{
  state: AuthState;
  dispatch: React.Dispatch<AuthAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

interface AuthContextProviderProps {
  children: React.ReactNode;
}

// Create the AuthContextProvider component
export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
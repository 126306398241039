import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import FeedIcon from '@mui/icons-material/Feed';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import FacebookIcon from '@mui/icons-material/Facebook';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import MuiComponents from "../MuiComponents";
import NotesApp from '../NotesApp';
import ExerciseApp from '../ExerciseApp';
import FoodApp from '../FoodApp';
import BlogsApp from '../BlogsApp';
import WebDesignApp from '../WebDesignApp';
import YoutubeApp from '../YoutubeApp';
import SocialMediaApp from '../SocialMediaApp';


export type RouteObjectProps = {
  routeName: string,
  component: React.ReactNode,
  path: string,
  icon: React.ReactElement
}

export const homePageRoutesSection1: RouteObjectProps[] = [
  { 
    routeName: 'Components',
    component: <MuiComponents />, 
    path: '/components',
    icon: <ViewQuiltIcon />
  },
];

export const homePageRoutesSection2: RouteObjectProps[] = [
  { 
    routeName: 'Notes',
    component: <NotesApp />, 
    path: '/notes',
    icon: <SpeakerNotesIcon />
  },
  { 
    routeName: 'Food',
    component: <FoodApp />, 
    path: '/food',
    icon: <TakeoutDiningIcon />
  },
  { 
    routeName: 'Exercise',
    component: <ExerciseApp />, 
    path: '/exercise',
    icon: <FitnessCenterIcon />
  },
  { 
    routeName: 'Blogs',
    component: <BlogsApp />, 
    path: '/blogs',
    icon: <FeedIcon />
  },
]

export const homePageRoutesSection3: RouteObjectProps[] = [
  { 
    routeName: 'Web Design',
    component: <WebDesignApp />, 
    path: '/webdesign',
    icon: <DesignServicesIcon />
  },
  { 
    routeName: 'Youtube',
    component: <YoutubeApp />, 
    path: '/youtube',
    icon: <OndemandVideoIcon />
  },
  { 
    routeName: 'Social Media',
    component: <SocialMediaApp />, 
    path: '/socialmedia',
    icon: <FacebookIcon />
  },
]
import { Typography } from "@mui/material"
import { Box } from "@mui/system"

const MainContent: React.FC = () => {
  return (
    <Box>
      <Typography variant="h2">Components </Typography>
    </Box>
  )
}

export default MainContent;
import axios, { AxiosRequestConfig } from "axios";
import { BASE_API_URL } from "../constants";
import { apiResponseErrorProps } from "../types";

const BASE_URL = BASE_API_URL;

interface ApiResponse<T> {
  data: T;
  success: boolean;
  error?: apiResponseErrorProps | unknown;
}

export const api = {
  async get<T>(url: string, config?: AxiosRequestConfig): Promise<ApiResponse<T | null>> {
    try {
      const response = await axios.get<T>(`${BASE_URL}/${url}`, config);
      return { data: response.data, success: true };
    } catch (error) {
      return { data: null, success: false, error: error };
    }
  },

  async post<T>(url: string, data: T, config?: AxiosRequestConfig): Promise<ApiResponse<T | null>> {
    try {
      const response = await axios.post<T>(`${BASE_URL}/${url}`, data, config);
      return { data: response.data, success: true };
    } catch (error) {
      return { data: null, success: false, error: error};
    }
  },

  async put<T>(url: string, data: T, config?: AxiosRequestConfig): Promise<ApiResponse<T | null>> {
    try {
      const response = await axios.put<T>(`${BASE_URL}/${url}`, data, config);
      return { data: response.data, success: true };
    } catch (error) {
      return { data: null, success: false, error: error };
    }
  },

  async delete<T>(url: string, config?: AxiosRequestConfig): Promise<ApiResponse<T | null>> {
    try {
      const response = await axios.delete<T>(`${BASE_URL}/${url}`, config);
      return { data: response.data, success: true };
    } catch (error) {
      return { data: null, success: false, error: error };
    }
  }
};

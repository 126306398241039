import Layout from "../../components/organisms/Layout";
import MainContent from "./MainContent";
import SideNavBar from "./SideNavBar";

const FoodApp = () => {
  return (
    <Layout SideNavBar={SideNavBar}>
      <MainContent />
    </Layout>
  )
}

export default FoodApp;
import React, { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import AbcIcon from "@mui/icons-material/Abc"; // Typography
import GridViewIcon from "@mui/icons-material/GridView"; // Form Controls
import DashboardIcon from "@mui/icons-material/Dashboard"; // layout
import AltRouteIcon from "@mui/icons-material/AltRoute"; // Navigation
import StorageIcon from "@mui/icons-material/Storage"; // Data Display
import WorkspacesIcon from "@mui/icons-material/Workspaces"; // @mui/Lab
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  typographyRoutes,
  formControlsRoutes,
  navigationRoutes,
  muiLabsRoutes,
  layoutRoutes,
  dataDisplayRoutes,
} from "./routes";

const listItemDivider = "#0000001f";

export const SideNavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const handleCollapseToggle = (panel: string) => {
    // Invert the state of the panel by toggling it each time the same panel is clicked.
    if (expanded === panel) {
      setExpanded(false);
    } else { // Toggle the state of the currently clicked panel and revert previous panel to its prior state.
      setExpanded(panel ? panel : false);
    }
  }

  const menuItems = [
    { icon: AbcIcon, label: "Typography", routes: typographyRoutes },
    {
      icon: GridViewIcon,
      label: "Form controls",
      routes: formControlsRoutes,
    },
    { icon: DashboardIcon, label: "Layout", routes: layoutRoutes },
    { icon: AltRouteIcon, label: "Navigation", routes: navigationRoutes },
    { icon: StorageIcon, label: "Data display", routes: dataDisplayRoutes },
    { icon: WorkspacesIcon, label: "Mui lab", routes: muiLabsRoutes },
  ];
  return (
    <Box>
      {menuItems.map((item, index) => (
        <List
          disablePadding
          key={`menuItems-${index}`}
          sx={{
            borderBottom: `1px solid var(--Divider-lineColor, ${listItemDivider})`,
            "&:first-of-type": {
              borderTop: `1px solid var(--Divider-lineColor, ${listItemDivider})`,
            },
          }}
        >
          <ListItemButton onClick={() => handleCollapseToggle(item.label)}>
            <ListItemIcon>
              {<item.icon color={expanded === item.label ? "primary" : "inherit"}/>}
            </ListItemIcon>
            <ListItemText primary={item.label} />
            {expanded === item.label ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={expanded === item.label} timeout="auto" unmountOnExit>
            <List disablePadding>
              {item.routes.map((item) => (
                <ListItem
                  divider
                  key={item.routeName}
                  onClick={() => navigate(item.routeName)}
                  disablePadding
                  sx={{
                    pl: 2,
                    bgcolor: location.pathname === item.routeName ? "#f3dbdb" : "initial",
                    "&:first-of-type": {
                      borderTop: `1px solid var(--Divider-lineColor, ${listItemDivider})`,
                    },
                  }}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      color="secondary"
                    >
                      <NavigateNextIcon />
                    </IconButton>
                  }
                >
                  <ListItemButton>
                    <ListItemText secondary={item.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      ))}
    </Box>
  );
};

export default SideNavBar;

import SubjectOutlinedIcon from '@mui/icons-material/SubjectOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Notes from "./components/Notes";
import CreateNote from './components/CreateNote';


export type RouteObjectProps = {
  index: boolean,
  label: string,
  path: string,
  routeName: string,
  component: React.ReactNode,
  icon: React.ReactElement
}

export const NotesAppRoutes: RouteObjectProps[] = [
  { 
    index: true,
    label: "My Notes",
    path: "",
    routeName: '/notes',
    component: <Notes />, 
    icon: <SubjectOutlinedIcon color="secondary" />,
  },
  {
    index: false,
    label: "Create Note",
    path: "new",
    routeName: '/notes/new',
    component: <CreateNote />,
    icon: <AddCircleOutlineOutlinedIcon color="secondary" />,
  },
];
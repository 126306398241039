import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import Copyright from "../../molecules/Copyright";
import Logo from "../../molecules/Logo";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { useLogin } from "../../../hooks/useLogin";

export interface LoginFormData {
  email: string;
  password: string;
}

const initialValue: LoginFormData = {
  email: "",
  password: ""
};

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<LoginFormData>(initialValue);
  const [errors, setErrors] = useState<Partial<LoginFormData>>({});
  const { login, error, isLoading } = useLogin();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validate()) {
      // Submit form data to server
      await login(formData);
      if (error === null) {
        setFormData(initialValue);
        navigate("/home");
        console.log("Login successful")
      }
    }
  };

  const validate = () => {
    let tempErrors: Partial<LoginFormData> = {};
    if (!formData.email) {
      tempErrors.email = "Email is required";
    }
    if (!formData.password) {
      tempErrors.password = "Password is required";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <Box sx={{ minHeight: "100vh", bgcolor: "black", display: "flex" }}>
      <Container
        maxWidth="md"
        component="main"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Paper elevation={3} sx={{ p: 6,  minWidth: {sm: "400px"}}}>
          <Stack spacing={4}>
            <Logo />
            {error && <div className="error">{error?.message}</div>}
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <TextField
                  required
                  fullWidth
                  name="email"
                  label="Email"
                  variant="outlined"
                  value={formData.email}
                  error={!!errors.email}
                  helperText={errors.email}
                  onChange={handleChange}
                />
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  variant="outlined"
                  value={formData.password}
                  error={!!errors.password}
                  helperText={errors.password}
                  onChange={handleChange}
                />
                <Button variant="contained" type="submit" disabled={isLoading}>
                  SIGN IN
                </Button>
              </Stack>
            </form>
            <Stack display="flex" direction={{xs: "column", sm: "row"}} justifyContent="space-between" spacing={{xs: 1}}>
              <Link component={RouterLink} to="/forgotpassword" variant="body2">
              Forgot password?
              </Link>
              <Link component={RouterLink} to="/signup" variant="body2">
              "Don't have an account? Sign Up"
              </Link>
            </Stack>
            <Copyright />
          </Stack>
        </Paper>
      </Container>
    </Box>
  );
};

export default Login;

import React from "react";

import MainContent from "./MainContent";

/*----------- Typography --------------*/
import { MuiTypography } from './components/Typography/MuiTypography';

/*----------- Form Elements --------------*/
import { MuiButton } from './components/FormControls/MuiButton';
import { MuiTextField } from './components/FormControls/MuiTextField';
import { MuiSelect } from './components/FormControls/MuiSelect';
import { MuiRadioButton } from './components/FormControls/MuiRadioButton';
import { MuiCheckbox } from './components/FormControls/MuiCheckbox';
import { MuiSwitch } from './components/FormControls/MuiSwitch';
import { MuiRating } from './components/FormControls/MuiRating';
import { MuiAutocomplete } from './components/FormControls/MuiAutocomplete';

/*----------- Layout --------------*/
import { MuiLayout } from './components/Layout/MuiLayout';
import { MuiCard } from './components/Layout/MuiCard';
import { MuiAccordion } from './components/Layout/MuiAccordion';
import { MuiImageList } from './components/Layout/MuiImageList';

/*----------- Navigation --------------*/
import { MuiNavbar } from './components/Navigation/MuiNavbar';
import { MuiLink } from './components/Navigation/MuiLink';
import { MuiBreadcrumbs } from './components/Navigation/MuiBreadcrumbs';
import { MuiDrawer } from './components/Navigation/MuiDrawer';
import { MuiTabs } from './components/Navigation/MuiTabs';
import { MuiSpeedDial } from './components/Navigation/MuiSpeedDial';
import { MuiBottomNavigation } from './components/Navigation/MuiBottomNavigation';

/*----------- Data Display --------------*/
import { MuiAvatar } from './components/DataDisplay/MuiAvatar';
import { MuiBadge } from './components/DataDisplay/MuiBadge';
import { MuiList } from './components/DataDisplay/MuiList';
import { MuiChip } from './components/DataDisplay/MuiChip';
import { MuiTooltip } from './components/DataDisplay/MuiTooltip';
import { MuiAlert } from './components/DataDisplay/MuiAlert';
import { MuiSnackbar } from './components/DataDisplay/MuiSnackbar';
import { MuiDialog } from './components/DataDisplay/MuiDialog';
import { MuiSkeleton } from './components/DataDisplay/MuiSkeleton';
import { MuiProgress } from './components/DataDisplay/MuiProgress';
import { MuiLoadingButton } from './components/DataDisplay/MuiLoadingButton';

/*----------- @Mui/Lab --------------*/
import { MuiDateTimePicker } from './components/MuiLab/MuiDateTimePicker';
import { MuiDateRangePicker } from './components/MuiLab/MuiDateRangePicker';
import { MuiTimeline } from './components/MuiLab/MuiTimeline';
import { MuiMasonry } from './components/MuiLab/MuiMasonry';

interface RouteObject {
  index: boolean;
  routeName: string;
  label: string;
  component: React.ReactNode;
  path: string;
}

export const baseRoute: RouteObject[] = [
  { 
    index: true,
    label: 'Home',
    path: '',
    component: <MainContent />,
    routeName: '/components/'
  }
];

export const typographyRoutes: RouteObject[] = [
  { 
    index: false,
    label: 'MuiTypography',
    path: 'MuiTypography',
    component: <MuiTypography />, 
    routeName: '/components/MuiTypography'
  }
];

export const formControlsRoutes: RouteObject[] = [
  { 
    index: false,
    label: 'MuiButton',
    path: 'MuiButton',
    component: <MuiButton />, 
    routeName: '/components/MuiButton' 
  },
  { 
    index: false,
    label: 'MuiTextField', 
    path: 'MuiTextField', 
    component: <MuiTextField />, 
    routeName: '/components/MuiTextField' 
  },
  { 
    index: false,
    label: 'MuiSelect', 
    path: 'MuiSelect', 
    component: <MuiSelect />, 
    routeName: '/components/MuiSelect' 
  },
  { 
    index: false,
    label: 'MuiRadioButton', 
    path: 'MuiRadioButton', 
    component: <MuiRadioButton />, 
    routeName: '/components/MuiRadioButton' 
  },
  { 
    index: false,
    label: 'MuiCheckbox', 
    path: 'MuiCheckbox', 
    component: <MuiCheckbox />,
    routeName: '/components/MuiCheckbox' 
  },
  { 
    index: false,
    label: 'MuiSwitch', 
    path: 'MuiSwitch', 
    component: <MuiSwitch />, 
    routeName: '/components/MuiSwitch' 
  },
  { 
    index: false,
    label: 'MuiRating', 
    path: 'MuiRating', 
    component: <MuiRating />, 
    routeName: '/components/MuiRating' 
  },
  { 
    index: false,
    label: 'MuiAutocomplete', 
    path: 'MuiAutocomplete', 
    component: <MuiAutocomplete />, 
    routeName: '/components/MuiAutocomplete' 
  }
];
export const navigationRoutes: RouteObject[] = [
  { 
    index: false,
    label: 'MuiNavbar', 
    path: 'MuiNavbar', 
    component: <MuiNavbar />, 
    routeName: '/components/MuiNavbar' 
  },
  { 
    index: false,
    label: 'MuiLink', 
    path: 'MuiLink', 
    component: <MuiLink />, 
    routeName: '/components/MuiLink' 
  },
  { 
    index: false,
    label: 'MuiBreadcrumbs', 
    path: 'MuiBreadcrumbs', 
    component: <MuiBreadcrumbs />, 
    routeName: '/components/MuiBreadcrumbs' 
  },
  { 
    index: false,
    label: 'MuiDrawer', 
    path: 'MuiDrawer', 
    component: <MuiDrawer />, 
    routeName: '/components/MuiDrawer' 
  },
  { 
    index: false,
    label: 'MuiTabs', 
    path: 'MuiTabs', 
    component: <MuiTabs />, 
    routeName: '/components/MuiTabs' 
  },
  { 
    index: false,
    label: 'MuiSpeedDial', 
    path: 'MuiSpeedDial', 
    component: <MuiSpeedDial />, 
    routeName: '/components/MuiSpeedDial' 
  },
  { 
    index: false,
    label: 'MuiBottomNavigation', 
    path: 'MuiBottomNavigation', 
    component: <MuiBottomNavigation />, 
    routeName: '/components/MuiBottomNavigation' 
  }
];
export const dataDisplayRoutes: RouteObject[] = [
  { 
    index: false,
    label: 'MuiAvatar', 
    path: 'MuiAvatar', 
    component: <MuiAvatar />, 
    routeName: '/components/MuiAvatar' 
  },
  { 
    index: false,
    label: 'MuiBadge', 
    path: 'MuiBadge', 
    component: <MuiBadge />, 
    routeName: '/components/MuiBadge' 
  },
  { 
    index: false,
    label: 'MuiList', 
    path: 'MuiList', 
    component: <MuiList />, 
    routeName: '/components/MuiList' 
  },
  { 
    index: false,
    label: 'MuiChip', 
    path: 'MuiChip', 
    component: <MuiChip />, 
    routeName: '/components/MuiChip' 
  },
  { 
    index: false,
    label: 'MuiTooltip', 
    path: 'MuiTooltip', 
    component: <MuiTooltip />, 
    routeName: '/components/MuiTooltip' 
  },
  { 
    index: false,
    label: 'MuiAlert', 
    path: 'MuiAlert', 
    component: <MuiAlert />, 
    routeName: '/components/MuiAlert' 
  },
  { 
    index: false,
    label: 'MuiSnackbar', 
    path: 'MuiSnackbar', 
    component: <MuiSnackbar />, 
    routeName: '/components/MuiSnackbar' 
  },
  { 
    index: false,
    label: 'MuiDialog', 
    path: 'MuiDialog', 
    component: <MuiDialog />, 
    routeName: '/components/MuiDialog' 
  },
  { 
    index: false,
    label: 'MuiSkeleton', 
    path: 'MuiSkeleton', 
    component: <MuiSkeleton />, 
    routeName: '/components/MuiSkeleton' 
  },
  { 
    index: false,
    label: 'MuiProgress', 
    path: 'MuiProgress', 
    component: <MuiProgress />, 
    routeName: '/components/MuiProgress' 
  },
  { 
    index: false,
    label: 'MuiLoadingButton', 
    path: 'MuiLoadingButton', 
    component: <MuiLoadingButton />, 
    routeName: '/components/MuiLoadingButton' 
  }
];
export const layoutRoutes: RouteObject[] = [
  { 
    index: false,
    label: 'MuiLayout', 
    path: 'MuiLayout', 
    component: <MuiLayout />, 
    routeName: '/components/MuiLayout' 
  },
  { 
    index: false,
    label: 'MuiCard', 
    path: 'MuiCard', 
    component: <MuiCard />, 
    routeName: '/components/MuiCard' 
  },
  { 
    index: false,
    label: 'MuiAccordion', 
    path: 'MuiAccordion', 
    component: <MuiAccordion />, 
    routeName: '/components/MuiAccordion'
  },
  { 
    index: false,
    label: 'MuiImageList', 
    path: 'MuiImageList', 
    component: <MuiImageList />, 
    routeName: '/components/MuiImageList' 
  },
];
export const muiLabsRoutes: RouteObject[] = [
  { 
    index: false,
    label: 'MuiDateTimePicker', 
    path: 'MuiDateTimePicker', 
    component: <MuiDateTimePicker />, 
    routeName: '/components/MuiDateTimePicker' 
  },
  { 
    index: false,
    label: 'MuiDateRangePicker', 
    path: 'MuiDateRangePicker', 
    component: <MuiDateRangePicker />, 
    routeName: '/components/MuiDateRangePicker' 
  },
  { 
    index: false,
    label: 'MuiTimeline', 
    path: 'MuiTimeline', 
    component: <MuiTimeline />, 
    routeName: '/components/MuiTimeline' 
  },
  { 
    index: false,
    label: 'MuiMasonry', 
    path: 'MuiMasonry', 
    component: <MuiMasonry />, 
    routeName: '/components/MuiMasonry' 
  },
];
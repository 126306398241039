import React, { useState, useEffect } from "react";
import { Container } from "@mui/system";
import { api } from "../../../apis/axios";
import Masonry from '@mui/lab/Masonry';
import NoteCard from "./NoteCard";

export type NotesProps = {
  "title": string,
  "details": string,
  "category": string
  "id": number
}

const Notes = () => {
  console.log("Loading ");
  const [notes, setNotes] = useState<NotesProps[]>([]);
  useEffect(() => {
    // TODO: Handle error state
    const getData = async () => {
      const { data, success, error } = await api.get<NotesProps[]>("/notes");
      if(success) {
        setNotes(data as NotesProps[] || []);
      } else {
        // Handle error condition
        console.log(error);
      }
    }
    getData();
  }, []);

  const handleDelete = async (id: number) => {
    await api.delete(`/notes/${id}`);
    const newNotes = notes.filter((note) => note.id !== id);
    setNotes(newNotes);
  };

  return (
    <Container>
     <Masonry columns={{ xs: 1, sm: 2, md: 3, lg:4 }} spacing={2}>
        {notes.map(note => (
          <div key={note.id}>
            <NoteCard note={note} handleDelete={handleDelete} />
          </div>
        ))}
      </Masonry>
    </Container>
  );
};

export default Notes;
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import image from "../../assets/images/under-construction.png";
import Logo from "../../molecules/Logo";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { Stack } from "@mui/system";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Button, Typography } from "@mui/material";
import { useLogout } from "../../../hooks/useLogout";

function LaunchPage() {
  const { logout } = useLogout();
  const { state } = useAuthContext();
  const handleClick = () => {
    logout();
  };
  return (
    <Box>
      <AppBar
        position="static"
        component="nav"
        sx={{
          bgcolor: "black",
          color: "white",
        }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Box
              sx={{
                display: "flex",
                minWidth: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Logo color="white" />
              </Box>
              <nav>
                {!state.isAuthenticated && (
                  <Stack direction="row">
                    <Box sx={{ pt: 0.5 }}>
                      <Link
                        component={RouterLink}
                        to="/login"
                        variant="button"
                        underline="none"
                        sx={{ color: "white", pt: "5px" }}
                      >
                        Login
                      </Link>
                    </Box>
                    <Box sx={{ pt: 0.5, ml: 2 }}>
                      <Link
                        component={RouterLink}
                        to="/signup"
                        variant="button"
                        underline="none"
                        sx={{ color: "white", pt: "5px" }}
                      >
                        Signup
                      </Link>
                    </Box>
                  </Stack>
                )}
                {state.isAuthenticated && (
                  <Stack direction="row">
                    <Box sx={{ pt: 0.5 }}>
                      <Typography variant="subtitle1" component="h6">
                        {state?.user?.email}
                      </Typography>
                    </Box>
                    <Box sx={{ ml: 2, color: "white" }}>
                      <Button
                        variant="text"
                        onClick={handleClick}
                        sx={{ color: "white" }}
                      >
                        Logout
                      </Button>
                    </Box>
                  </Stack>
                )}
              </nav>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="main">
        <Box component="section">
          <Container
            maxWidth="lg"
            sx={{
              p: 0,
              minHeight: `calc(100vh - 70px)`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={image}
              alt="Tree"
              loading="lazy"
              style={{ width: "300px", height: "300px" }}
            />
          </Container>
        </Box>
      </Box>
    </Box>
  );
}
export default LaunchPage;

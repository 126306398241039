import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { FormData } from "../components/pages/Signup";
import { apiResponseErrorProps } from "../types";
import { BASE_API_URL } from "../constants";

export const useSignup = () => {
  const [error, setError] = useState<apiResponseErrorProps | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useAuthContext();

  const signup = async ( {email, password, firstName, lastName, acceptTnC}: FormData ): Promise<void> => {
    setIsLoading(true);
    setError(null);

    const response = await fetch(`${BASE_API_URL}/api/users/register`, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({email, password, firstName, lastName, acceptTnC})
    });

    const json = await response.json();

    if(!response.ok) {
      setIsLoading(false);
      setError(json);
    }

    if(response.ok) {
      //save the user to local storage
      localStorage.setItem("user", JSON.stringify(json));

      // update the suth context
      dispatch({ type: "LOGIN", payload: json });

      // update loading state
      setIsLoading(false);
    }
  }

  return { signup, isLoading, error };
}